<template>
  <div class="navbar-wrapper">
    <v-app-bar
      :value="true"
      fixed
      color="#1B263B"
      flat
      :elevate-on-scroll="true"
    >
      <v-toolbar-title class="center-text" :class="{'ml-16': !isMobile, 'logo-center': isMobile}">
        <a class="link" href="https://rebrand.ly/Bojek567">
          <img class="mt-2 company-logo" src="../assets/logo.png" alt="">
        </a>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <div v-if="!isMobile">
        <a class="link mr-5" href="https://rebrand.ly/Bojek567"><span>Register</span></a>
        <a class="link mr-5" href="https://rebrand.ly/Bojek567">Login</a>
        <a class="link mr-5" href="https://rebrand.ly/Bojek567">Promo</a>
        <a class="link mr-5" href="https://rebrand.ly/Bojek567">Provider</a>
        <a class="link mr-16" href="https://rebrand.ly/Bojek567">Whatsapp</a>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: 'NavigationBar',
  data () { 
    return {
      sheet: false
    }
  },
  props: ['isShowNav'],
  computed: {
    isMobile() {
      return window.innerWidth < 600;
    },
  },
  methods: {
    openMenu () {
      this.sheet = true
    }
  }
}
</script>

<style>
.navbar-wrapper {
  padding-bottom: 4rem;
}

.company-logo {
  width: 12rem;
}

span {
  color: #E0E1DD !important;
  text-decoration: none;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

span:hover {
  color: #8f8e86;
}

.link {
  /* color: #3f3b37 !important; */
  color: #E0E1DD !important;
  text-decoration: none;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.link:hover {
  color: #8f8e86;
}


/* MOBILE */
.nav-menu {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.theme--light.v-sheet {
  color: #E0E1DD;
  background-color: #0D1B2A;
}

.menu-item {
  font-size: 55px;
  font-weight: 400;
  line-height: 85px;
  width: 100%;
  padding-left: 1rem;
}

a {
  color: #c1c0b6 !important;
  text-decoration: none;
}

.logo-center {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

</style>